import React from "react";
import greenbluebook from "../../assets/img/png/greenbluebooks.png";

const MathematicalJourney = () => {
  return (
    <div className="py-[48px] overflow-x-hidden">
      <div className="max-w-[1140px] mx-auto px-[24px]">
        <div className="lg:flex text-center lg:text-start justify-between gap-8 items-start">
          <h2 className="lora fs_xxl font-bold max-w-[600px] mx-auto">
            <span className="flex gap-3 justify-center lg:justify-start">
              <span className="text-[#FFC346]">Embark on</span>{" "}
              <span className="flex items-center gap-4">
                a{" "}
                <img
                  className="md:w-[45px] w-[35px]"
                  src={greenbluebook}
                  alt=""
                />
              </span>{" "}
            </span>
            Mathematical Journey with{" "}
            <span className="text-[#23A198]">Miss Asha</span>
          </h2>
          <p className="arial fs_xxsm text-[#636363] max-w-[590px] lg:max-w-[510px] mx-auto mt-5">
            Welcome to our Mathematics Hub! With a wealth of expertise and a
            passion for teaching, we're dedicated to making math an exciting
            journey for all. Explore Asha mam's story and join us in discovering
            the joy of numbers together.
          </p>
        </div>
      </div>
    </div>
  );
};

export default MathematicalJourney;
