import React, { useEffect } from "react";
import {
  dollar,
  fiveStars,
  globe,
  openRightArrow,
  teacher,
  teacherBlackColor,
} from "../Mapdata/MapData";
import girlstudying from "../../assets/img/png/girlstudyingbook.png";
import { useParams } from "react-router-dom";

const CourseDetailing = () => {
  const { heading } = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="pt-[48px]">
      <div className="max-w-[1140px] mx-auto px-[24px]">
        <div className="lg:flex justify-between">
          <div>
            <h2 className="text-[#23A198] lora fs_xxl font-bold max-w-[640px] lg:max-w-[540px] mx-auto lg:mx-0 text-center lg:text-start">
              {decodeURIComponent(heading)}
            </h2>
            <div className="sm:flex gap-7 mt-5">
              <div className="flex items-center gap-3">
                {teacher}
                <p className="poppins font-medium text-[#081E1C] fs_xxsm">
                  By Miss Asha Kaswan
                </p>
              </div>
              <div className="md:flex hidden items-center gap-3">
                {globe}
                <p className="poppins font-medium text-[#081E1C] fs_xxsm">
                  Motivation
                </p>
              </div>
              <div className="flex items-center gap-3 mt-5 md:mt-0">
                {fiveStars}
                <p className="poppins font-medium text-[#081E1C] fs_xxsm">
                  (5.0/60 Ratings)
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className="w-[100%]">
              <img
                className="sm:w-[400px] w-[100%] mx-auto lg:mx-0 mt-8"
                src={girlstudying}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseDetailing;
