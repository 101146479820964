import React from "react";
import { openRightArrow, redPlaybtn, yellowTick } from "./Mapdata/MapData";
import teachergivingthumsup from "../assets/img/png/teacherGivingThumbsup.png";
import teacheraskingquestion from "../assets/img/png/studentAndTeacher.png";
import igcsebar from "../assets/img/png/IGCSEbar.png";
import { useNavigate } from "react-router-dom";
import colorLine from "../assets/img/png/heroAbsolute2.png";

const MathsSkills = () => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate("/ContactUs");
  };
  return (
    <div className="relative">
      <img
        className="absolute right-[0%] top-[27%] w-[80px] md:w-[150px] z-[-1]"
        src={colorLine}
        alt="yellow line"
      />
      <div className="max-w-[1140px] mx-auto px-[26px] lg:px-3 py-[48px]">
        <div className="flex lg:flex-row flex-col lg:gap-[70px] items-center">
          <div className="">
            <div className="text-center lg:text-start">
              <h2 className="lora fs_xxl font-bold">
                Grow your Maths skills{" "}
                <span className="text-[#23A198] sm:block">from anywhere</span>
              </h2>
              <p className="arial fs_xxsm text-[#636363] mt-4">
                Empower yourself to grow math skills from anywhere with our
                <br /> accessible learning online and offline.
              </p>
            </div>
            <div>
              <div className="flex items-center gap-3 mt-7">
                {yellowTick}
                <p className="poppins font-medium text-[#101A2E]">
                  Expert Mathematics Training
                </p>
              </div>
              <div className="flex items-center gap-3 mt-7">
                {yellowTick}
                <p className="poppins font-medium text-[#101A2E]">
                  Online & Offline Learning
                </p>
              </div>
              <div className="flex items-center gap-3 mt-7">
                {yellowTick}
                <p className="poppins font-medium text-[#101A2E]">
                  Boards & Examination Covered
                </p>
              </div>
            </div>
            <button
              className="bg-[#23A198] arial mt-[30px] mx-auto lg:mx-0 py-[22px] px-10 flex items-center text-lg font-bold text-white arial rounded-full joinbtn_shadow"
              type="button"
              onClick={handleNavigation}
            >
              GET STARTED
              {openRightArrow}
            </button>
          </div>
          <div className="relative mt-[90px] xl:mt-[0]">
            <img
              style={{ boxShadow: "0px 35px 65px 0px #23A19833" }}
              className="w-[100%] rounded-[18px]"
              src={teachergivingthumsup}
              alt="teachergivingthumsup"
            />
            <div className="absolute z-[1] top-[-15%] xxl:right-[-18%] md:right-[-25%] xl:right-[-5%] lg:right-[60%] right-[-5%]">
              <div
                className="bg-white p-[18px] md:w-[200px] w-[160px] rounded-[12px] relative"
                style={{
                  boxShadow: "0px 14px 52px 0px rgba(17, 101, 95, 0.2)",
                }}
              >
                <div className="absolute left-[50%] translate-x-[-50%] top-[40%] translate-y-[-60%]">
                  {redPlaybtn}
                </div>
                <img
                  className="w-[100%]"
                  src={teacheraskingquestion}
                  alt="teacher asking question"
                />
                <div className="w-[80%] h-[9px] rounded-[12px] mt-4 bg-[#D2D2D2]"></div>
                <div className="w-[50%] h-[9px] rounded-[12px] mt-4 bg-[#D2D2D2]"></div>
              </div>
            </div>
            <div
              style={{ boxShadow: "0px 35px 65px 0px #23A19833" }}
              className="absolute bottom-[-8%] xxl:right-[-18%] lg:right-[-1%] xl:right-[-5%] right-[20%] bg-[white] rounded-[14px]"
            >
              <img className="w-[180px]" src={igcsebar} alt="igcse" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MathsSkills;
