import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const AdminAuthentication = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const uri = process.env.REACT_APP_MONGODB_URI + "/adminlogin";

  const handleSubmit = (e) => {
    e.preventDefault();
    const adminlogindata = {
      adminmail: email,
      password: password,
    };
    // Handle form submission logic here
    axios
      .post(uri, adminlogindata)
      .then((response) => {
        navigate("/mathsmastery/admin/login/otpVerification");
      })
      .catch((error) => {
        console.error("Error adding product:", error);
      });
  };

  return (
    <div className="bg-[#23A198] flex justify-center items-center h-screen">
      <div
        style={{ boxShadow: "0px 0px 150px 0px #FFFFFF" }}
        className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-80"
      >
        <div className="mb-4">
          <h1 className="text-center fs_xxl font-bold mb-4 lora text-[#23A198]">
            Login
          </h1>
          <p className="text-gray-700 text_md acumin_pro text-center">
            Enter your credentials to access your account
          </p>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2 acumin_pro"
              htmlFor="email"
            >
              Email Address
            </label>
            <input
              className="acumin_pro shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>
          <div className="mb-6">
            <label
              className="block text-gray-700 text-sm font-bold mb-2 acumin_pro"
              htmlFor="password"
            >
              Password
            </label>
            <input
              className="acumin_pro shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="password"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
          </div>
          <div className="text-center">
            <button
              className="bg-[#23A198] transition duration-300 ease-in-out hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline acumin_pro"
              type="submit"
            >
              Verify Otp
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdminAuthentication;
