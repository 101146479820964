import React from "react";

const TailoredLearning = () => {
  return (
    <div className="pt-[36px]">
      <div className="max-w-[1140px] mx-auto px-[24px]">
        <div className="lg:flex justify-between text-center lg:text-start">
          <div>
            <h2 className="lora fs_xxl font-bold max-w-[640px] lg:max-w-[540px]  mx-auto">
              <span className="text-[#FFC346]">Tailored</span> Learning,
              Affordable Pricing:{" "}
              <span className="text-[#23A198] lg:block">Find Your Plan</span>
            </h2>
          </div>
          <div>
            <p className="arial mt-[24px] fs_xxsm text-[#636363] max-w-[550px] lg:max-w-[500px] lg:ms-auto mx-auto">
              Our course pricing is designed to make quality education
              accessible to all. Explore a range of affordable packages tailored
              to meet your specific learning goals. Invest in your mathematical
              journey with transparent pricing and unlock a world of knowledge
              at your fingertips.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TailoredLearning;
