import React from "react";
import Slider from "react-slick";
import { useState } from "react";
import {
  featureCardData,
  rightFaceArrow,
  rightFaceArrowslick,
  scholarCap,
} from "./Mapdata/MapData";
import books from "../assets/img/png/Books.png";
import { useNavigate } from "react-router-dom";
import colorLine from "../assets/img/png/heroAbsolute2.png";

const BestFeatures = () => {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const navigate = useNavigate();
  const button = React.useRef();
  // const handlenavigation = () => {
  //   navigate("/mathsmastery/admin/login");
  // };
  return (
    <section className="pb-12 overflow-x-hidden best-feature-sec relative">
      <img
        className="absolute left-[-1%] top-[30%] md:top-[0%] w-[80px] md:w-[150px] z-[-1]"
        src={colorLine}
        alt="yellow line"
      />
      <div className="max-w-[1140px] mx-auto sm:px-[26px] px-[1px] lg:px-3">
        <div className="">
          <div className="flex flex-col-reverse lg:flex-row">
            <Slider
              className="lg:w-[60%] w-[100%]"
              asNavFor={nav1}
              ref={(slider2) => {
                setNav2(slider2);
                button.current = slider2;
              }}
              slidesToShow={2}
              swipeToSlide={true}
              focusOnSelect={true}
              speed={700}
              dots={true}
              arrows={true}
              responsive={[
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                  },
                },
                {
                  breakpoint: 640,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  },
                },
              ]}
            >
              {featureCardData.map((items, index) => {
                return (
                  <div className="px-3 py-[60px]">
                    <div
                      key={index}
                      className="xl:max-w-[280px] max-w-[400px] sm:w-[100%] mx-auto rounded-3xl bg-white p-5"
                      style={{
                        boxShadow: "1px 6px 22px 2px rgba(11, 59, 55, 0.4)",
                      }}
                    >
                      <div
                        className={`${items.maincirclebg} rounded-full w-[80px] h-[80px] flex items-center justify-center`}
                      >
                        {scholarCap}
                      </div>
                      <div className="mt-5">
                        <h3 className="poppins font-semibold fs_md">
                          Full Syllabus
                        </h3>
                        <p className="arial fs_xxsm mt-4">
                          Master the full math syllabus for deep understanding
                          and strong problem-solving
                        </p>
                        <div
                          // onClick={handlenavigation}
                          className="cursor-pointer w-[47px] h-[47px] bg-[#FA786B] rounded-full mt-4 ms-auto flex items-center justify-center"
                        >
                          {rightFaceArrow}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
            <div className="md:ps-5 text-center lg:text-start mt-10">
              <h2 className="lora fs_xxl font-bold">
                Our best features{" "}
                <span className="text-[#23A198]">Special for you</span>
              </h2>
              <p className="arial fs_xxsm text-[#636363] mt-3">
                Diverse expertise in IB, IGCSE, GCSE, American curriculum, and
                CBSE boards.
              </p>
              <div className="mt-3">
                <img
                  className="w-[48px] mx-auto lg:mx-0 hidden lg:block"
                  src={books}
                  alt="books"
                />
              </div>
              <button
                className="hidden lg:block next-btn bg-[#23A198] relative z-10 mt-[34px] w-[40px] h-[40px] rounded-[8px]"
                onClick={() => button.current.slickNext()}
              >
                {rightFaceArrowslick}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BestFeatures;
