import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import CourseDetailing from "../components/DetailedCourses/CourseDetailing";
import CourseDescription from "../components/DetailedCourses/CourseDescription";
import GetInTouch from "../components/GetInTouch";
import Faq from "../components/Faq";
import Footer from "../components/Footer";

const CourseDetails = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar />
      <CourseDetailing />
      <CourseDescription />
      <GetInTouch />
      <Faq />
      <Footer />
    </div>
  );
};

export default CourseDetails;
