import React, { useState } from "react";
import ibCurriculum from "../assets/img/png/ibCurriculum.png";
import {
  CourseBooksCards,
  clock,
  openRightArrow,
  slidernextbuttonarrow,
  sliderprevbuttonarrow,
  yellowStar,
} from "./Mapdata/MapData";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";

const EmpoweringMinds = ({ courseNone }) => {
  const [options, setoptions] = useState(1);
  const [extraclasscard, setextraclasscard] = useState(false);
  const [cards, setcards] = useState(CourseBooksCards.slice(0, 4));
  const button = React.useRef();
  const navigate = useNavigate();

  const handleCourseDetail = (heading) => {
    navigate(`/CourseDetails/${encodeURIComponent(heading)}`);
  };

  const handlecourse = () => {
    setoptions(1);
    setcards(CourseBooksCards.slice(0, 4));
  };
  const handlebooks = () => {
    setoptions(2);
    setcards(CourseBooksCards.slice(5, 8));
  };
  const handleexams = () => {
    setoptions(3);
    setcards(CourseBooksCards.slice(8));
  };
  const handleextraclass = () => {
    setoptions(4);
    setextraclasscard(true);
  };

  return (
    <div className="overflow-x-hidden max-w-[1140px] mx-auto sm:px-[26px] px-[1px] lg:px-3 py-[48px] sec-empowering-mind">
      <h2 className={`lora fs_xxl font-bold text-center ${courseNone}`}>
        Empowering Minds at <span className="text-[#23A198]">Every Stage</span>
      </h2>
      <div>
        <div className="mt-[36px] px-3 flex items-center max-w-[550px] sm:max-w-[100%] sm:justify-center mx-auto gap-[40px] overflow-x-scroll">
          <p
            onClick={handlecourse}
            className={`poppins fs_sm cursor-pointer whitespace-nowrap ${
              options === 1 ? "text-[#23A198] font-semibold" : "text-[#081E1C]"
            }`}
          >
            Boards
          </p>
          <div className="h-[30px] w-[2px] bg-[#DEE1E1]"></div>
          <p
            onClick={handlebooks}
            className={`poppins fs_sm cursor-pointer whitespace-nowrap ${
              options === 2 ? "text-[#23A198] font-semibold" : "text-[#081E1C]"
            }`}
          >
            Books
          </p>
          <div className="h-[30px] w-[2px] bg-[#DEE1E1]"></div>
          <p
            onClick={handleexams}
            className={`poppins fs_sm cursor-pointer whitespace-nowrap ${
              options === 3 ? "text-[#23A198] font-semibold" : "text-[#081E1C]"
            }`}
          >
            Exams
          </p>
          <div className="h-[30px] w-[2px] bg-[#DEE1E1]"></div>
          <p
            onClick={handleextraclass}
            className={`poppins fs_sm cursor-pointer whitespace-nowrap ${
              options === 4 ? "text-[#23A198] font-semibold" : "text-[#081E1C]"
            }`}
          >
            Extra classes
          </p>
        </div>
        {options === 4 ? (
          <div className="px-3 mt-16">
            <div className="bg-[#23A198] max-w-[350px] p-[24px] mx-auto border-[1px] rounded-[18px] h-[100%]">
              <div>
                <div className="flex flex-col items-start mt-3">
                  <div className="flex items-center gap-[9px]">
                    {clock}{" "}
                    <p className="poppins font-medium pt-[2px] text-[#FFFFFF]">
                      4:30 - 7:30 PM
                    </p>
                  </div>
                  <h4 className="poppins font-semibold fs_sm mt-2 text-[#FFFFFF]">
                    Extra classes are available on the request of learners
                  </h4>
                  <p className="arial font-medium pt-[2px] text-[#FFFFFF] mt-4">
                    Extra classes offering full syllabus coverage, dedicated
                    answers to queries, and personalized focus on understanding
                    for an enhanced learning experience.
                  </p>
                  <div className="mt-4 flex gap-3 items-center">
                    <div className="flex">
                      {yellowStar}
                      {yellowStar}
                      {yellowStar}
                      {yellowStar}
                      {yellowStar}
                    </div>
                    <p className="poppins fs_2xsm font-medium text-[#FFFFFF]">
                      (5.0/60 Ratings)
                    </p>
                  </div>
                  <p className="poppins font-semibold fs_sm text-[#FFFFFF] mt-3">
                    120 AED PER HOUR
                  </p>
                </div>
              </div>
              <div>
                <button
                  className="bg-[#FA786B] lg:mx-0 py-[10px] mt-4 px-[20px] flex items-center text-lg font-bold text-white arial rounded-full joinbtn_shadow"
                  type="button"
                >
                  SUBMIT A REQUEST {openRightArrow}
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <Slider
              className="relative"
              ref={(slider2) => {
                button.current = slider2;
              }}
              slidesToShow={3}
              swipeToSlide={true}
              focusOnSelect={true}
              speed={500}
              dots={false}
              arrows={true}
              responsive={[
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                  },
                },
                {
                  breakpoint: 640,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  },
                },
              ]}
            >
              {cards.map((items, index) => {
                return (
                  <div className="px-3 mt-10">
                    <div
                      onClick={() => handleCourseDetail(items.courseName)}
                      key={index}
                      className="bg-white flex flex-col justify-between p-[18px] border-[1px] rounded-[18px] h-[100%]"
                    >
                      <div>
                        <img
                          className="w-[100%]"
                          src={items.img}
                          alt="courseImg"
                        />

                        <div className="flex flex-col items-start mt-3">
                          <div className="flex items-center gap-[9px]">
                            {clock}{" "}
                            <p className="poppins font-medium pt-[2px]">
                              {items.time}
                            </p>
                          </div>
                          <h4 className="poppins font-semibold fs_sm mt-2">
                            {items.courseName}
                          </h4>
                          <div className="mt-3 flex gap-3 items-center">
                            <div className="flex">
                              {yellowStar}
                              {yellowStar}
                              {yellowStar}
                              {yellowStar}
                              {yellowStar}
                            </div>
                            <p className="poppins fs_2xsm font-medium text-[#585F6D]">
                              {items.rating}
                            </p>
                          </div>
                          <p className="poppins font-semibold fs_sm text-[#23A198] mt-3">
                            {items.charges}
                          </p>
                        </div>
                      </div>
                      <div>
                        <button
                          className="bg-[#23A198] lg:mx-0 py-[10px] mt-4 px-[20px] flex items-center text-lg font-bold text-white arial rounded-full joinbtn_shadow"
                          type="button"
                        >
                          COURSE DETAILS {openRightArrow}
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
            <div className="flex justify-center gap-[24px]">
              <button
                className="flex items-center justify-center border-2 border-[#23A198] active:scale-[0.8] transition-all z-10 mt-[34px] w-[40px] h-[40px] rounded-[100%]"
                onClick={() => button.current.slickPrev()}
              >
                {sliderprevbuttonarrow}
              </button>
              <button
                className="flex items-center justify-center active:scale-[0.8]  border-2 border-[#23A198] transition-all z-10 mt-[34px] w-[40px] h-[40px] rounded-[100%]"
                onClick={() => button.current.slickNext()}
              >
                {slidernextbuttonarrow}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EmpoweringMinds;
