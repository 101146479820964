import React, { useEffect, useRef, useState } from "react";
import { callLogo, mailLogo, mapPin } from "../Mapdata/MapData";
import FormData from "form-data";
import axios from "axios";

const ContactForm = () => {
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [mail, setMail] = useState("");
  const [number, setNumber] = useState("");
  const [classes, setClasses] = useState("");
  const [board, setBoard] = useState("");
  const [book, setBook] = useState("");
  const [exam, setExam] = useState("");
  const [extraClasses, setExtraClasses] = useState(false);
  const [message, setMessage] = useState("");
  const [formError, setFormError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const alertRef = useRef(null);
  const uri = process.env.REACT_APP_MONGODB_URI + "/studentsData";

  // Options for dropdowns
  const classOptions = [
    "Choose your class",
    "8",
    "9",
    "10",
    "+1",
    "+2",
    "University",
  ];

  const boardOptions = [
    "Choose a board",
    "IB CURRICULUM",
    "IGCSE CURRICULUM ",
    "American CURRICULUM ",
    "GCSE CURRICULUM",
    "CBSE CURRICULUM",
  ];
  const bookOptions = [
    "Choose a book",
    "International GCSE Mathematics Extended",
    "Cambridge Mathematics",
    "IGCSE Mathematics A",
  ];
  const examOptions = [
    "Choose an exam",
    "IGCSE Exam preparation",
    "EDXCEL Exam preparation",
    "International GCSE Mathematics Extended",
    "PapaCambridge exam papers",
  ];

  useEffect(() => {
    if (formError) {
      alertRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [formError]);

  useEffect(() => {
    if (successMessage) {
      alertRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [successMessage]);

  useEffect(() => {
    let timeoutId;
    if (successMessage) {
      timeoutId = setTimeout(() => {
        setSuccessMessage("");
      }, 3000); // 3 seconds
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [successMessage]);

  const submit = async () => {
    setLoading(true);
    if (!firstname || !lastname || !mail || !number || !classes) {
      setFormError("All fields are required.");
      setSuccessMessage("");
      return;
    }

    if (!extraClasses) {
      setExtraClasses(false); // Ensure extraClasses is set to false if not checked
    }
    // Construct username from first name and last name
    const userName = `${firstname} ${lastname}`;

    const userData = {
      username: userName,
      usermail: mail,
      number: number,
      classes: classes,
      board: board.trim() || "X",
      book: book.trim() || "X",
      exam: exam.trim() || "X",
      extraclasses: extraClasses,
      message: message.trim() || "X",
    };

    try {
      await axios.post(uri, userData);
      setLoading(false);
      setSuccessMessage("Registration has been successfully done.");
      setFormError("");
      // Clearing form fields after successful submission
      setFirstName("");
      setLastName("");
      setMail("");
      setNumber("");
      setClasses("");
      setBoard("");
      setBook("");
      setExam("");
      setExtraClasses(false);
      setMessage("");
    } catch (error) {
      console.log("Error occurred while submitting form:", error);
    }
  };

  return (
    <div className="md:py-[48px] py-[24px] contact-form">
      <div className="max-w-[1140px] mx-auto sm:px-[26px] px-[12px] lg:px-3">
        <div className="flex flex-col lg:flex-row gap-[50px] lg:justify-between justify-center items-cente">
          <div>
            <h2 className="lora fs_xxl font-bold">
              Get in <span className="text-[#23A198]">Touch</span>
            </h2>
            <p className="poppins fs_xxsm text-[#0E0E0E] max-w-[402px] mt-[18px]">
              Whether you have questions about our courses or just want to say
              hello, we're here to assist you on your math-filled journey.
            </p>
            <div className="mt-[34px]">
              <div className="flex items-center gap-[12px]">
                <div className="bg-[#23A198] w-[50px] h-[50px] rounded-full flex items-center justify-center">
                  {mapPin}
                </div>
                <div>
                  <p className="poppins fs_xxsm text-[#23A198] font-semibold">
                    Our Location
                  </p>
                  <p className="poppins fs_xxsm text-[#0E0E0E]">
                    Wasl village Dubai UAE
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-[12px] mt-[32px]">
                <div className="bg-[#23A198] w-[50px] h-[50px] rounded-full flex items-center justify-center">
                  {mailLogo}
                </div>
                <div>
                  <p className="poppins fs_xxsm text-[#23A198] font-semibold">
                    Email Address
                  </p>
                  <p className="poppins fs_xxsm text-[#0E0E0E]">
                    ashakaswan42@gmail.com
                  </p>
                </div>
              </div>
              <div className="flex items-center gap-[12px] mt-[32px]">
                <div className="bg-[#23A198] w-[50px] h-[50px] rounded-full flex items-center justify-center">
                  {callLogo}
                </div>
                <div>
                  <p className="poppins fs_xxsm text-[#23A198] font-semibold">
                    Phone Number
                  </p>
                  <p className="poppins fs_xxsm text-[#0E0E0E]">
                    (+971) 055-251-8226
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="">
            <div ref={alertRef}>
              {formError && (
                <div
                  className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                  role="alert"
                >
                  <strong className="font-bold">Error:</strong>
                  <span className="block sm:inline">{formError}</span>
                </div>
              )}
              {successMessage && (
                <div
                  className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative"
                  role="alert"
                >
                  <strong className="font-bold">Success:</strong>
                  <span className="block sm:inline">{successMessage}</span>
                </div>
              )}
            </div>
            <div className="flex flex-col sm:flex-row gap-5 mt-[12px]">
              <div className="flex flex-col">
                <label
                  className="arial fs_xxsm text-[#0E0E0E]"
                  htmlFor="FirstName"
                >
                  First Name
                </label>
                <input
                  className="border-[1px] w-[100%] sm:w-[280px] mt-[6px] border-[#E6E6E6] rounded-[6px] arial fs_xxsm text-[#0E0E0E]"
                  placeholder="eg:- Jhon "
                  type="text"
                  value={firstname}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </div>
              <div className="flex flex-col">
                <label
                  className="arial fs_xxsm text-[#0E0E0E]"
                  htmlFor="LastName"
                >
                  Last Name
                </label>
                <input
                  className="border-[1px] w-[100%] sm:w-[280px] mt-[6px] border-[#E6E6E6] rounded-[6px] arial fs_xxsm text-[#0E0E0E]"
                  placeholder="eg:- Smith "
                  type="text"
                  value={lastname}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </div>
            </div>
            <div className="flex flex-col sm:flex-row gap-5 mt-[30px]">
              <div className="flex flex-col">
                <label className="arial fs_xxsm text-[#0E0E0E]" htmlFor="email">
                  Email
                </label>
                <input
                  className="border-[1px] w-[100%] sm:w-[280px] mt-[6px] border-[#E6E6E6] rounded-[6px] arial fs_xxsm text-[#0E0E0E]"
                  placeholder="Example_mail@gmail.com"
                  type="email"
                  value={mail}
                  onChange={(e) => setMail(e.target.value)}
                />
              </div>
              <div className="flex flex-col">
                <label
                  className="arial fs_xxsm text-[#0E0E0E]"
                  htmlFor="number"
                >
                  Phone Number
                </label>
                <input
                  className="border-[1px] mt-[6px] w-[100%] sm:w-[280px] border-[#E6E6E6] rounded-[6px] arial fs_xxsm text-[#0E0E0E]"
                  placeholder="eg:- +12 123 456 7890"
                  type="number"
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                />
              </div>
            </div>
            <div className="flex flex-col sm:flex-row gap-5 mt-[30px]">
              <div className="flex flex-col">
                <label className="arial fs_xxsm text-[#0E0E0E]" htmlFor="class">
                  Class/University
                </label>
                <select
                  className="border-[1px] w-[100%] sm:w-[280px] mt-[6px] border-[#E6E6E6] rounded-[6px] arial fs_xxsm text-[#cecece]"
                  value={classes}
                  onChange={(e) => setClasses(e.target.value)}
                >
                  {classOptions.map((option, index) => (
                    <option
                      style={{
                        color: option === classes ? "#cecece" : "#0E0E0E",
                      }}
                      key={index}
                      value={option}
                    >
                      {option}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex flex-col">
                <label className="arial fs_xxsm text-[#0E0E0E]" htmlFor="board">
                  Board
                </label>
                <select
                  className="border-[1px] w-[100%] sm:w-[280px] mt-[6px] border-[#E6E6E6] rounded-[6px] arial fs_xxsm text-[#cecece]"
                  value={board}
                  onChange={(e) => setBoard(e.target.value)}
                >
                  {boardOptions.map((option, index) => (
                    <option
                      style={{
                        color: option === classes ? "#cecece" : "#0E0E0E",
                      }}
                      key={index}
                      value={option}
                    >
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="flex flex-col sm:flex-row gap-5 mt-[30px]">
              <div className="flex flex-col">
                <label className="arial fs_xxsm text-[#0E0E0E]" htmlFor="book">
                  Book
                </label>
                <select
                  className="border-[1px] w-[100%] sm:w-[280px] mt-[6px] border-[#E6E6E6] rounded-[6px] arial fs_xxsm text-[#cecece]"
                  value={book}
                  onChange={(e) => setBook(e.target.value)}
                >
                  {bookOptions.map((option, index) => (
                    <option
                      style={{
                        color: option === classes ? "#cecece" : "#0E0E0E",
                      }}
                      key={index}
                      value={option}
                    >
                      {option}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex flex-col">
                <label className="arial fs_xxsm text-[#0E0E0E]" htmlFor="exam">
                  Exam
                </label>
                <select
                  className="border-[1px] w-[100%] sm:w-[280px] mt-[6px] border-[#E6E6E6] rounded-[6px] arial fs_xxsm text-[#cecece]"
                  value={exam}
                  onChange={(e) => setExam(e.target.value)}
                >
                  {examOptions.map((option, index) => (
                    <option
                      style={{
                        color: option === classes ? "#cecece" : "#0E0E0E",
                      }}
                      key={index}
                      value={option}
                    >
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="flex flex-col mt-[30px]">
              <label
                className="arial fs_xxsm text-[#0E0E0E]"
                htmlFor="extraClasses"
              >
                Extra Classes
              </label>
              <div className="flex items-center gap-[36px]">
                <div className="flex items-center gap-2 mt-[12px]">
                  <input
                    type="radio"
                    id="yes"
                    name="extraClasses"
                    value="Yes"
                    checked={extraClasses === true}
                    onChange={() => setExtraClasses(true)}
                  />
                  <label htmlFor="yes" className="arial text-[#0E0E0E]">
                    Yes
                  </label>
                </div>
                <div className="flex items-center gap-2 mt-[12px]">
                  <input
                    type="radio"
                    id="no"
                    name="extraClasses"
                    value="No"
                    checked={extraClasses === false}
                    onChange={() => setExtraClasses(false)}
                  />
                  <label htmlFor="no" className="arial text-[#0E0E0E]">
                    No
                  </label>
                </div>
              </div>
            </div>
            <div className="flex flex-col mt-[30px]">
              <label className="arial fs_xxsm text-[#0E0E0E]" htmlFor="number">
                Message
              </label>
              <textarea
                rows={4}
                className="border-[1px] mt-[6px] w-[100%]  border-[#E6E6E6] rounded-[6px] arial fs_xxsm text-[#0E0E0E]"
                placeholder="Your text here..."
                type="text"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
            <div>
              <button
                onClick={submit}
                className="bg-[#23A198] mt-[30px] py-[18px] px-10 flex items-center text-lg font-bold text-white arial rounded-full joinbtn_shadow"
                type="button"
              >
                SUBMIT
              </button>
            </div>
          </div>
        </div>
      </div>
      {loading && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-32 w-32"></div>
        </div>
      )}
    </div>
  );
};

export default ContactForm;
