import React, { useEffect } from "react";
import MathematicalJourney from "../components/AboutUs/MathematicalJourney";
import Navbar from "../components/Navbar";
import StudentsTaught from "../components/AboutUs/StudentsTaught";
import StoryBehindNumbers from "../components/StoryBehindNumbers";
import FeedBacks from "../components/FeedBacks";
import UnlockMathsUniverse from "../components/UnlockMathsUniverse";
import GetInTouch from "../components/GetInTouch";
import Faq from "../components/Faq";
import Footer from "../components/Footer";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar />
      <MathematicalJourney />
      <StudentsTaught />
      <UnlockMathsUniverse />
      <StoryBehindNumbers />
      <FeedBacks />
      <GetInTouch />
      <Faq />
      <Footer />
    </div>
  );
};

export default About;
