import React from "react";
import books from "../assets/img/png/greenbluebooks.png";
import { openRightArrow, yellowPlaybtn, yellowTick } from "./Mapdata/MapData";
import girlholdingbooks from "../assets/img/png/girlHoldingBooks.png";
import teacheraskingquestion from "../assets/img/png/studentAndTeacher.png";
import dots from "../assets/img/png/dotsbox.png";
import greenbluebook from "../assets/img/png/greenbluebooks.png";
import { useNavigate } from "react-router-dom";

const StoryBehindNumbers = () => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate("/ContactUs");
  };
  return (
    <div className="max-w-[1140px] mx-auto px-[26px] lg:px-3 py-[48px]">
      <div className="flex lg:flex-row flex-col lg:gap-[90px] items-center">
        <div className="">
          <div className="text-center lg:text-start">
            <img className="w-100" src={greenbluebook} alt="" />
            <h2 className="lora fs_xxl font-bold">
              Unveiling the Story{" "}
              <span className="text-[#23A198] sm:block">Behind Numbers!</span>
            </h2>
            <div className="flex gap-3 items-center mt-4">
              <div className="h-[60px] w-[2px] bg-[#FFC346]"></div>
              <p className="arial fs_xxsm text-[#636363]">
                Elevate your mathematical proficiency through our expert
                <br /> math training program, designed to empower learners at
                every level.
              </p>
            </div>
          </div>
          <div>
            <div className="flex items-center gap-3 mt-7">
              {yellowTick}
              <p className="poppins font-medium text-[#101A2E]">
                Expert Mathematics Training
              </p>
            </div>
            <div className="flex items-center gap-3 mt-7">
              {yellowTick}
              <p className="poppins font-medium text-[#101A2E]">
                Online & Offline Learning
              </p>
            </div>
            <div className="flex items-center gap-3 mt-7">
              {yellowTick}
              <p className="poppins font-medium text-[#101A2E]">
                Boards & Examination Covered
              </p>
            </div>
          </div>
          <button
            className="bg-[#23A198] arial mt-[30px] mx-auto lg:mx-0 py-[22px] px-10 flex items-center text-lg font-bold text-white arial rounded-full joinbtn_shadow"
            type="button"
            onClick={handleNavigation}
          >
            GET STARTED
            {openRightArrow}
          </button>
        </div>
        <div className="relative mt-[90px] xl:mt-[0]">
          <img
            className="absolute top-[-10%] z-[-1] right-[12%]"
            src={dots}
            alt="dots"
          />
          <img
            style={{ boxShadow: "0px 35px 65px 0px #23A19833" }}
            className="w-[100%] rounded-[18px]"
            src={girlholdingbooks}
            alt="girlholdingbooks"
          />
          <div className="absolute z-[1] top-[-15%] xxl:right-[-18%] md:right-[-25%] xl:right-[-2%] lg:right-[60%] right-[-5%]">
            <div
              className="bg-white p-[18px] md:w-[200px] w-[160px] rounded-[12px] relative"
              style={{ boxShadow: "0px 14px 52px 0px rgba(17, 101, 95, 0.2)" }}
            >
              <div className="absolute left-[50%] translate-x-[-50%] top-[40%] translate-y-[-60%]">
                {yellowPlaybtn}
              </div>
              <img
                className="w-[100%]"
                src={teacheraskingquestion}
                alt="teacher asking question"
              />
              <div className="w-[80%] h-[9px] rounded-[12px] mt-4 bg-[#D2D2D2]"></div>
              <div className="w-[50%] h-[9px] rounded-[12px] mt-4 bg-[#D2D2D2]"></div>
            </div>
          </div>
          <div
            style={{ boxShadow: "0px 35px 65px 0px #23A19833" }}
            className="absolute sm:w-[250px] py-[18px] border-l-[7px] border-l-[#23A198] px-[26px] bottom-[-8%] xxl:left-[-11%] lg:left-[-1%] xl:left-[-5%] left-[20%] translate-x-[-10%] xs:translate-x-[0%] bg-[white] rounded-[14px]"
          >
            <div className="flex items-center gap-3">
              <p className="poppins text-[#23A198] fs_xl font-bold">20+</p>
              <p className="arial font-bold fs_sm">
                Years of <br /> Experience
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoryBehindNumbers;
