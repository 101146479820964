import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const OtpVerify = () => {
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const uri = process.env.REACT_APP_MONGODB_URI + "/otpverify";
  const handleOtpVerification = async () => {
    try {
      const otpResponse = await axios.post(uri, { otp });

      if (otpResponse.data.success) {
        const token = otpResponse.data.token;
        console.log("token", token);
        localStorage.setItem("token", token);
        alert("OTP Verified. User logged in.");
        navigate("/mathsmastery/admin/home");
      } else {
        alert("Invalid OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error during OTP verification:", error.message);
      alert("An error occurred during OTP verification");
    }
  };

  return (
    <div className="login-container bg-[#23A198] flex justify-center flex-col items-center h-screen">
      <div
        style={{ boxShadow: "0px 0px 150px 0px #FFFFFF" }}
        className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 w-80"
      >
        <h1 className="text-center fs_xxl font-bold mb-4 lora text-[#23A198]">
          OTP
        </h1>
        <div>
          <div className="py-5">
            <label
              className="block text-gray-700 text-sm font-bold mb-2 acumin_pro text-start"
              htmlFor="otp"
            >
              Enter OTP
            </label>
            <input
              className="acumin_pro w-full shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="text"
              placeholder="OTP"
              onChange={(e) => setOtp(e.target.value)}
            />
          </div>
          <div className="text-center">
            <button
              className="bg-[#23A198] w-[100px] mt-4 transition duration-300 ease-in-out hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline acumin_pro"
              onClick={handleOtpVerification}
            >
              LogIn
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtpVerify;
