import React, { useState } from "react";
import boyworkingonlaptop from "../assets/img/png/unlockMathsUniverseimg.png";
import topwave from "../assets/img/png/unlockMathsUniversetopwave.png";
import bottomwave from "../assets/img/png/unlockMathsUniversebottomwave.png";
import { mathuniverseCards } from "./Mapdata/MapData";

const UnlockMathsUniverse = () => {
  const [firstcards, setfirstcards] = useState(mathuniverseCards.slice(0, 2));
  const [secondcards, setsecondcards] = useState(mathuniverseCards.slice(2, 4));

  return (
    <div className="py-[56px]">
      <img className="w-[100%] sm:h-[120px]" src={topwave} alt="" />
      <div className="bg-[#EBF6F7] py-[40px]">
        <div className="max-w-[1140px] mx-auto px-[26px] lg:px-3">
          <h2 className="lora fs_xxl font-bold text-center">
            Unlock the <span className="text-[#23A198]">Math Universe</span>
          </h2>
          <div className="flex flex-col-reverse lg:flex-row items-center mt-[48px] lg:mt-24 justify-between gap-[30px]">
            <div>
              <img
                className="w-100"
                src={boyworkingonlaptop}
                alt="boyWorkingonlaptop"
              />
            </div>
            <div className="sm:flex gap-[18px]">
              <div className="flex pe-[25px] sm:pe-[0] flex-col gap-[28px]">
                {firstcards.map((items, index) => {
                  return (
                    <div key={index}>
                      <div
                        style={{
                          boxShadow:
                            "0px 11.067961692810059px 41.10956954956055px 0px #11655F33",
                        }}
                        className="bg-white max-w-[270px] p-[16px] sm:p-[24px] rounded-[18px]"
                      >
                        <div>{items.scgimg}</div>
                        <h4 className="text-[#23A198] poppins font-bold fs_sm mt-[10px]">
                          {items.head}
                        </h4>
                        <p className="arial fs_xxsm text-[#636363] mt-[10px]">
                          {items.para}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="mt-[33px] ps-[25px] sm:ps-[0] flex flex-col gap-[28px]">
                {secondcards.map((items, index) => {
                  return (
                    <div key={index}>
                      <div
                        style={{
                          boxShadow:
                            "0px 11.067961692810059px 41.10956954956055px 0px #11655F33",
                        }}
                        className="bg-white max-w-[270px] p-[16px] sm:p-[24px] rounded-[18px]"
                      >
                        <div>{items.scgimg}</div>
                        <h4 className="text-[#23A198] poppins font-bold fs_sm mt-[10px]">
                          {items.head}
                        </h4>
                        <p className="arial fs_xxsm text-[#636363] mt-[10px]">
                          {items.para}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <img className="w-[100%] sm:h-[120px]" src={bottomwave} alt="" />
    </div>
  );
};

export default UnlockMathsUniverse;
