import { Route, Routes } from "react-router-dom";
import "./App.css";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "flowbite";
import AdminPanel from "./components/adminPanel/AdminPanel";
import Home from "./pages/Home";
import AdminAuthentication from "./components/adminPanel/AdminAuthentication";
import OtpVerify from "./components/adminPanel/OtpVerify";
import About from "./pages/About";
import Courses from "./pages/Courses";
import CourseDetails from "./pages/CourseDetails";
import { useEffect } from "react";
import Contact from "./pages/Contact";
function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Aboutus" element={<About />} />
        <Route path="/Courses" element={<Courses />} />
        <Route path="/CourseDetails/:heading" element={<CourseDetails />} />
        <Route path="/ContactUs" element={<Contact />} />
        <Route
          path="/mathsmastery/admin/login"
          element={<AdminAuthentication />}
        />
        <Route
          path="/mathsmastery/admin/login/otpVerification"
          element={<OtpVerify />}
        />
        <Route path="/mathsmastery/admin/home" element={<AdminPanel />} />
      </Routes>
    </>
  );
}

export default App;
