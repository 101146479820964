import React from "react";
import flowerImg from "../assets/img/svg/flowerImg.svg";
import heroImg from "../assets/img/png/hero-img.png";
import boardCoverd from "../assets/img/svg/5board.svg";
import activeStudent from "../assets/img/svg/active-student.svg";
import smallHeroImg from "../assets/img/png/small-hero-img.png";
import { openRightArrow } from "./Mapdata/MapData";
import { useNavigate } from "react-router-dom";
import curverdLine from "../assets/img/png/heroAbsolute.png";
import colorLine from "../assets/img/png/heroAbsolute2.png";
const HeroSection = () => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate("/ContactUs");
  };
  return (
    <div className="relative overflow-x-hidden">
      <img
        className="absolute left-[0] top-[45%] sm:top-[3%] md:top-[0] lg:top-[12%] w-[150px] sm:w-[140px] md:w-[200px] z-[-1]"
        src={curverdLine}
        alt="yellow line"
      />
      <img
        className="absolute right-[0%] top-[35%] sm:top-[27%] w-[80px] md:w-[150px] z-[-1]"
        src={colorLine}
        alt="yellow line"
      />
      <div className="max-w-[1140px] mx-auto px-[26px] lg:px-3">
        <div className="flex flex-col lg:flex-row items-center pt-10 lg:pt-8 lg:pb-[67px]">
          <div className="w-full lg:w-1/2 text-center lg:text-start">
            <h1 className=" leading-[120%] md:leading-[120%] fs_3xl font-bold lora">
              {" "}
              <span className="text-[#F8AD16] block lg:inline">
                Take your time{" "}
              </span>
              and learn from anywhere{" "}
              <span className=" inline-block">
                <img
                  className="w-[64px] h-[64px] hidden lg:flex"
                  src={flowerImg}
                  alt="flowerImg"
                />
              </span>
            </h1>
            <p className="text-xl arial font-normal text-[#828282] pt-4 pb-[35px] md:pt-5 md:pb-10">
              A learning system based on formalised teaching
              <span className="lg:block">
                {" "}
                but with the help of electronic resources
              </span>
            </p>
            <button
              className="bg-[#23A198] mx-auto lg:mx-0 py-[18px] px-10 flex items-center text-lg font-bold text-white arial rounded-full joinbtn_shadow"
              type="button"
              onClick={handleNavigation}
            >
              JOIN NOW
              {openRightArrow}
            </button>
          </div>
          <div className="w-full lg:w-1/2 relative">
            <img
              className="mx-auto lg:hidden mt-8"
              src={smallHeroImg}
              alt="smallHeroImg"
            />
            <img className="hidden lg:block" src={heroImg} alt="heroImg" />
            <img
              className="hidden lg:block absolute top-[172px] 2xl:top-[100px] right-0 2xl:right-[-48px] joinbtn_shadow rounded-3xl w-[200px] xl:w-[219px] h-[99px]"
              src={boardCoverd}
              alt="board-coverd"
            />
            <img
              className="hidden lg:block absolute left-[-110px] bottom-[-15px]"
              src={activeStudent}
              alt="active-students"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
