import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import TailoredLearning from "../components/Courses/TailoredLearning";
import EmpoweringMinds from "../components/EmpoweringMinds";
import FeedBacks from "../components/FeedBacks";
import GetInTouch from "../components/GetInTouch";
import Faq from "../components/Faq";
import Footer from "../components/Footer";

const Courses = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar />
      <TailoredLearning />
      <EmpoweringMinds courseNone="hidden" />
      <FeedBacks />
      <GetInTouch />
      <Faq />
      <Footer />
    </div>
  );
};

export default Courses;
