import React from "react";
import aboutusimg from "../../assets/img/png/aboutusimg.png";
import { openRightArrow } from "../Mapdata/MapData";

const StudentsTaught = () => {
  return (
    <div>
      <div className="max-w-[1140px] mx-auto px-[24px] py-[48px]">
        <div className="flex flex-col lg:flex-row items-center justify-between">
          <div className="aboutusimg">
            <img className="aboutusimg w-[550px]" src={aboutusimg} alt="" />
          </div>
          <div>
            <div className="flex justify-around lg:justify-between gap-14 mt-8 md:mt-12 w-[100%] lg:w-[unset]">
              <div className="flex flex-col justify-between gap-14">
                <div>
                  <h3 className="text-[#23A198] poppins font-bold fs_xxl">
                    3000+
                  </h3>
                  <p className="arial fs_sm text-[#636363]">Students taught</p>
                </div>
                <div>
                  <h3 className="text-[#23A198] poppins font-bold fs_xxl">
                    20+
                  </h3>
                  <p className="arial fs_sm text-[#636363]">
                    Years of Experience
                  </p>
                </div>
              </div>
              <div className="flex flex-col justify-between gap-14">
                <div>
                  <h3 className="text-[#23A198] poppins font-bold fs_xxl">
                    6+
                  </h3>
                  <p className="arial fs_sm text-[#636363]">
                    Flexible Teaching Methods
                  </p>
                </div>
                <div>
                  <h3 className="text-[#23A198] poppins font-bold fs_xxl">
                    830+
                  </h3>
                  <p className="arial fs_sm text-[#636363]">Positive Reviews</p>
                </div>
              </div>
            </div>
            <button
              className="bg-[#23A198] mx-auto md:mx-0 arial mt-[30px] py-[22px] px-10 flex items-center text-lg font-bold text-white rounded-full joinbtn_shadow"
              type="button"
            >
              GET STARTED
              {openRightArrow}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentsTaught;
