import React, { useState } from "react";
import topwave from "../assets/img/png/unlockMathsUniversetopwave.png";
import faqimg from "../assets/img/png/faqsecimg.png";
import {
  accrodionArrow,
  accrodionArrowWhite,
  faqdata,
} from "./Mapdata/MapData";

const Faq = () => {
  const [expandedAccordion, setExpandedAccordion] = useState(0); // Setting the first accordion (ID: 1) as expanded by default

  const handleAccordionClick = (accordionId) => {
    if (expandedAccordion === accordionId) {
      setExpandedAccordion(null); // Collapse the accordion if it's already expanded
    } else {
      setExpandedAccordion(accordionId); // Expand the accordion
    }
  };

  return (
    <div className="pt-[56px]">
      <img className="w-[100%] sm:h-[120px]" src={topwave} alt="" />
      <div id="#Faq" className="bg-[#EBF6F7] py-[40px]">
        <div className="max-w-[1140px] mx-auto px-[26px] lg:px-3">
          <div className="xl:flex items-center gap-[50px]">
            <div>
              <h2 className="lora fs_xxl font-bold text-center xl:text-start">
                Got Questions?
                <br />
                Here is <span className="text-[#23A198]">The FAQ</span>
              </h2>
              <p className="arial fs_xxsm max-w-[700px] xl:max-w-[375px] mt-4 text-center mx-auto xl:mx-[0] xl:text-start">
                Explore our FAQ section for quick insights into our courses,
                pricing, and more. Find the information you need to embark on
                your mathematical journey with confidence.
              </p>
              <div>
                <div
                  className="sm:w-[540px] rounded-[24px] mx-auto xl:mx-[0] mt-10"
                  id="accordion-collapse"
                  data-accordion="collapse"
                >
                  {faqdata.map((items, accordionId) => (
                    <React.Fragment key={accordionId}>
                      <div
                        className={`rounded-[24px] mt-4 ${
                          expandedAccordion === accordionId
                            ? "text-[#E7F2EF] bg-[#23A198]"
                            : "text-black"
                        }`}
                      >
                        <h2 id={`accordion-collapse-heading-${accordionId}`}>
                          <button
                            type="button"
                            className={`flex items-center justify-between w-full p-5 font-semibold text-start  ${
                              expandedAccordion === accordionId
                                ? "text-[#E7F2EF] bg-[#23A198]"
                                : "text-black"
                            } rounded-[24px] gap-3 fs_sm`}
                            data-accordion-target={`#accordion-collapse-body-${accordionId}`}
                            aria-expanded={
                              expandedAccordion === accordionId
                                ? "true"
                                : "false"
                            }
                            aria-controls={`accordion-collapse-body-${accordionId}`}
                            onClick={() => handleAccordionClick(accordionId)}
                          >
                            <span
                              className={
                                expandedAccordion === accordionId
                                  ? "text-[#E7F2EF]"
                                  : "text-black"
                              }
                            >
                              {items.head}
                            </span>
                            {expandedAccordion === accordionId
                              ? accrodionArrowWhite
                              : accrodionArrow}
                          </button>
                        </h2>
                        <div
                          id={`accordion-collapse-body-${accordionId}`}
                          className={`${
                            expandedAccordion === accordionId ? "" : "hidden"
                          } ${
                            expandedAccordion === accordionId
                              ? ""
                              : "bg-transparent"
                          }`}
                          aria-labelledby={`accordion-collapse-heading-${accordionId}`}
                        >
                          <div className="px-5 pb-5 text-white">
                            <p className="mb-2 text-[#BBE1DC] poppins fs_xxsm md:pe-9">
                              {items.para}
                            </p>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </div>
            <div className="mt-10">
              <img
                className="w-[100%] sm:w-[80%] xl:w-[100%] mx-auto faq-img"
                src={faqimg}
                alt="teacher"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
