import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import HeroSection from "../components/HeroSection";
import BestFeatures from "../components/BestFeatures";
import Footer from "../components/Footer";
import MathsSkills from "../components/MathsSkills";
import UnlockMathsUniverse from "../components/UnlockMathsUniverse";
import StoryBehindNumbers from "../components/StoryBehindNumbers";
import EmpoweringMinds from "../components/EmpoweringMinds";
import Faq from "../components/Faq";
import GetInTouch from "../components/GetInTouch";
import FeedBacks from "../components/FeedBacks";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      <HeroSection />
      <BestFeatures />
      <MathsSkills />
      <UnlockMathsUniverse />
      <StoryBehindNumbers />
      <EmpoweringMinds />
      <FeedBacks />
      <GetInTouch />
      <Faq />
      <Footer />
    </>
  );
};

export default Home;
