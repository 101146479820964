import axios from "axios";
import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  copySvg,
  deleteIcon,
  tickSvg,
  prevPaginationArrow,
  nextPaginationArrow,
} from "../Mapdata/MapData";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";

const AdminPanel = () => {
  const [studentsdata, setStudentsdata] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [copiedText, setCopiedText] = useState("");
  const [tdIndex, setTdIndex] = useState(null);
  const [isemailCopied, setIsEmailCopied] = useState(false);
  const [isnumberCopied, setIsNumberCopied] = useState(false);
  const [deletedItems, setDeletedItems] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [searchInput, setSearchInput] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [perPage] = useState(25);

  const navigate = useNavigate();
  const uri = process.env.REACT_APP_MONGODB_URI + "/studentsData";

  const handleCopy = (text, index) => {
    setCopiedText(text);
    setTdIndex(index);
  };

  const emailCopied = (text, index) => {
    setIsEmailCopied(true);
    handleCopy(text, index);
    setIsNumberCopied(false);
    setTimeout(() => {
      setIsEmailCopied(false);
    }, 800);
  };

  const numberCopied = (text, index) => {
    setIsNumberCopied(true);
    handleCopy(text, index);
    setIsEmailCopied(false);
    setTimeout(() => {
      setIsNumberCopied(false);
    }, 800);
  };

  const getUser = async () => {
    try {
      axios.get(uri).then((res) => {
        setStudentsdata(res.data);
        setFilteredData(res.data);
        console.log(res);
      });
    } catch (error) {
      console.log("error =>", error);
    }
  };

  const deletstudentData = (studentId, index) => {
    setDeletedItems([...deletedItems, index]);
    axios
      .delete(`${uri}/${studentId}`)
      .then((response) => {
        console.log("Student data removed:", response.data); // Remove the catch block
        // Refresh the data after deletion
        getUser();
      })
      .catch((error) => {
        console.error("Error removing student data:", error);
      });
  };

  const pageCount = Math.ceil(filteredData.length / perPage);
  const offset = currentPage * perPage;

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/mathsmastery/admin/login");
  };
  const handlereload = () => {
    window.location.reload();
  };

  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
    // Apply filter based on the selected filter and search text
    applyFilter(studentsdata, filter, searchText);
  };

  const applyFilter = (data, filter, searchText) => {
    let filteredData = data;
    if (filter === "Couse") {
      filteredData = data.filter((item) =>
        item?.classes.toLowerCase().includes(searchText.toLowerCase())
      );
    } else if (filter === "Boards") {
      filteredData = data.filter((item) =>
        item?.board.toLowerCase().includes(searchText.toLowerCase())
      );
    } else if (filter === "Books") {
      filteredData = data.filter((item) =>
        item?.book.toLowerCase().includes(searchText.toLowerCase())
      );
    } else if (filter === "Exams") {
      filteredData = data.filter((item) =>
        item?.exam.toLowerCase().includes(searchText.toLowerCase())
      );
    } else if (filter === "Extra Classes") {
      filteredData = data.filter((item) =>
        item?.extraclasses.toLowerCase().includes(searchText.toLowerCase())
      );
    }
    setFilteredData(filteredData);
  };

  useEffect(() => {
    getUser();
    console.log(studentsdata);
    const token = localStorage.getItem("token");
    console.log("token practice", token);
    if (!token) {
      navigate("/mathsmastery/admin/login"); // Redirect to login page only if token is not found
    }
  }, []);

  useEffect(() => {
    // Apply filter whenever filter or search text changes
    applyFilter(studentsdata, selectedFilter, searchText);
  }, [studentsdata, selectedFilter, searchText]);

  return (
    <div className="max-w-[1140px] mx-auto py-8 px-2">
      <h1 className="lora fs_3xl text-center text-[#23A198] font-bold">
        Replies
      </h1>
      <div className="mt-14">
        <div className="flex gap-4">
          <button
            onClick={handleLogout}
            className="bg-[#23A198] w-[120px] h-[40px] rounded-md text-white font-semibold lora"
          >
            Log Out
          </button>
          <button
            onClick={handlereload}
            className="bg-[#0d6efd] w-[120px] h-[40px] rounded-md text-white font-semibold lora"
          >
            Refresh
          </button>
        </div>
        <div className="mt-7 flex items-start gap-8 overflow-x-auto">
          <div className="flex items-center gap-2">
            <input
              className=""
              type="radio"
              name="filter"
              value="Couse"
              checked={selectedFilter === "Couse"}
              onChange={() => handleFilterChange("Couse")}
              onClick={(e) => setSearchInput(true)}
            />
            <p className="acumin_pro pt-[2px]">Couse</p>
          </div>
          <div className="flex items-center gap-2">
            <input
              className=""
              type="radio"
              name="filter"
              value="Boards"
              checked={selectedFilter === "Boards"}
              onChange={() => handleFilterChange("Boards")}
              onClick={(e) => setSearchInput(true)}
            />
            <p className="acumin_pro pt-[2px]">Boards</p>
          </div>
          <div className="flex items-center gap-2">
            <input
              className=""
              type="radio"
              name="filter"
              value="Books"
              checked={selectedFilter === "Books"}
              onChange={() => handleFilterChange("Books")}
              onClick={(e) => setSearchInput(true)}
            />
            <p className="acumin_pro pt-[2px]">Books</p>
          </div>
          <div className="flex items-center gap-2">
            <input
              className=""
              type="radio"
              name="filter"
              value="Exams"
              checked={selectedFilter === "Exams"}
              onChange={() => handleFilterChange("Exams")}
              onClick={(e) => setSearchInput(true)}
            />
            <p className="acumin_pro pt-[2px]">Exams</p>
          </div>
          <div className="flex items-center gap-2">
            <input
              className=""
              type="radio"
              name="filter"
              value="Extra Classes"
              checked={selectedFilter === "Extra Classes"}
              onChange={() => handleFilterChange("Extra Classes")}
            />
            <p className="whitespace-nowrap acumin_pro pt-[2px]">
              Extra Classes
            </p>
          </div>
        </div>

        {searchInput === true ? (
          <div className="mt-3 mb-7">
            <input
              className="peer bg-transparent text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border text-sm px-3 py-2 rounded-[7px] border-blue-gray-200 focus:border-gray-900"
              type="text"
              placeholder="Enter the value"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th className="px-6 py-3 whitespace-nowrap">S No.</th>
              <th className="px-6 py-3 whitespace-nowrap">Name</th>
              <th className="px-6 py-3 whitespace-nowrap">Mail</th>
              <th className="px-6 py-3 whitespace-nowrap">Ph. Number</th>
              <th className="px-6 py-3 whitespace-nowrap">Couse</th>
              <th className="px-6 py-3 whitespace-nowrap">Boards</th>
              <th className="px-6 py-3 whitespace-nowrap">Books</th>
              <th className="px-6 py-3 whitespace-nowrap">Exams</th>
              <th className="px-6 py-3 whitespace-nowrap">Extra Classes</th>
              <th className="px-6 py-3 whitespace-nowrap">Message</th>
              <th className="px-6 py-3 whitespace-nowrap">Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredData
              .slice(offset, offset + perPage)
              .reverse()
              .map((item, index) => {
                return (
                  <tr
                    key={index}
                    className={`${
                      index % 2 === 0
                        ? "bg-gray-50 dark:bg-gray-800 "
                        : "bg-white dark:bg-gray-900 "
                    } `}
                  >
                    <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      <div className="flex items-center gap-3 text-[20px]">
                        {index + 1}
                      </div>
                    </td>
                    <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                      {item.username}
                    </td>
                    <td
                      className="px-6 py-4 whitespace-nowrap"
                      onClick={() => emailCopied(item.usermail, index)}
                    >
                      <div className="flex items-center gap-2 cursor-pointer">
                        {item.usermail}
                        {isemailCopied === true && tdIndex === index ? (
                          <span>{tickSvg}</span>
                        ) : (
                          <CopyToClipboard
                            text={item.usermail}
                            onClick={() => emailCopied(item.usermail, index)}
                          >
                            <span className="cursor-pointer">{copySvg}</span>
                          </CopyToClipboard>
                        )}
                      </div>
                    </td>
                    <td
                      onClick={() => numberCopied(item.number, index)}
                      className="px-6 py-4 whitespace-nowrap"
                    >
                      <div className="flex items-center gap-2 cursor-pointer">
                        {item.number}
                        {isnumberCopied && tdIndex === index ? (
                          <span>{tickSvg}</span>
                        ) : (
                          <CopyToClipboard
                            text={item.number}
                            onCopy={() => numberCopied(item.number, index)}
                          >
                            <span className="cursor-pointer">{copySvg}</span>
                          </CopyToClipboard>
                        )}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {item?.classes}{" "}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {item?.board}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {item?.book}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {item?.exam}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-center">
                      {item && item.extraclasses
                        ? item.extraclasses.toString()
                        : "False"}
                    </td>
                    <td className="px-6 py-4 whitespace-normal">
                      {item?.message}
                    </td>
                    <td className="px-6 py-4 whitespace-normal">
                      <span
                        onClick={() => deletstudentData(item._id, index)}
                        className=" cursor-pointer"
                      >
                        {deleteIcon}
                      </span>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <div className="pagination-container">
        <ReactPaginate
          previousLabel={prevPaginationArrow}
          nextLabel={nextPaginationArrow}
          pageCount={pageCount}
          onPageChange={handlePageChange}
          containerClassName={"pagination mb-0"}
          previousLinkClassName={"pagination__link"}
          nextLinkClassName={"pagination__link"}
          pageClassName={"pagination__page fw-medium fs_xsm"}
          disabledClassName={"pagination__link--disabled"}
          activeClassName={
            "pagination__link--active text-white fw-medium fs_xsm"
          }
        />
      </div>
    </div>
  );
};

export default AdminPanel;
