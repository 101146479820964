import React from "react";
import greendots from "../assets/img/png/roundgreendotsbox.png";
import orangedots from "../assets/img/png/roundorangedotsbox.png";
import bgvector from "../assets/img/png/getintouchbg.png";

const GetInTouch = () => {
  return (
    <div className="py-[48px] overflow-x-clip px-4">
      <div className="max-w-[1140px] px- mx-auto get-in-touch-bg px-[50px] py-[100px] rounded-[47px] relative">
        <img
          className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%] w-[100%]"
          src={bgvector}
          alt="vector"
        />
        <img
          className="absolute top-[-25%] left-[-8%] z-[-1] w-[180px]"
          src={greendots}
          alt="greendots"
        />
        <img
          className="absolute bottom-[-25%] right-[-8%] z-[-1] w-[180px]"
          src={orangedots}
          alt="greendots"
        />
        <div className="flex lg:flex-row flex-col justify-center gap-[50px] items-center">
          <div className="lg:text-end text-center">
            <p className="arial fs_md text-white">Get In Touch:</p>
            <a
              href="mailto:ashakaswan42@gmail.com"
              className="acumin_pro font-bold fs_lg text-white relative z-[10]"
            >
              ashakaswan42@gmail.com
            </a>
          </div>
          <div className="bg-white min-w-[89px] min-h-[89px] border-[7px] border-[#23A19833] rounded-[100%] flex items-center justify-center">
            <p className="arial dont-bold fs_lg text-[#32B7AD]">OR</p>
          </div>

          <div className="lg:text-start text-center w-[340px]">
            <p className="arial fs_md text-white">Call Us Via:</p>
            <a
              href="tel:+055-251-8226"
              className="acumin_pro font-bold fs_xl text-white relative z-[10]"
            >
              +055-251-8226
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetInTouch;
