import React from "react";
import {
  dollar,
  openRightArrow,
  teacherBlackColor,
  tickSvg,
  yellowTick,
} from "../Mapdata/MapData";
import { useNavigate } from "react-router-dom";

const CourseDescription = () => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate("/ContactUs");
  };
  return (
    <div className="pb-[48px]">
      <div className="max-w-[1140px] mx-auto px-[24px]">
        <div className="flex flex-col-reverse lg:flex-row lg:justify-between gap-8">
          <div>
            <div className="text-center lg:text-start">
              <h3 className="poppins font-medium fs_xl">Course Description</h3>
              <p className="arial fs_xxsm text-[#000000] max-w-[670px] mt-4 mx-auto lg:mx-0">
                With a comprehensive approach, this course covers the entire
                syllabus, emphasizing key topics frequently featured in
                examinations. We prioritize regular assessments to gauge
                individual progress and provide targeted support for
                strengthening weaker areas.
              </p>
              <p className="arial fs_xxsm text-[#000000] max-w-[670px] mt-4 mx-auto lg:mx-0">
                Additionally, special classes are available upon request. Our
                commitment extends to offering flexible class schedules tailored
                to learners' availability and ease of understanding, ensuring a
                personalized and effective learning experience.
              </p>
            </div>
            <div>
              <h3 className="poppins font-medium fs_xl mt-8 text-center lg:text-start">
                What you’ll learn from this course
              </h3>
              <div className="flex flex-col max-w-[700px] mx-auto">
                <div className="mt-6 flex items-center gap-3">
                  {yellowTick}
                  <p className="poppins font-medium fs_xxsm text-[#101A2E]">
                    Learn all main topics thoroughly for a well-rounded
                    understanding.
                  </p>
                </div>
                <div className="mt-6 flex items-center gap-3">
                  {yellowTick}
                  <p className="poppins font-medium fs_xxsm text-[#101A2E]">
                    Emphasize frequently asked questions in examinations for
                    targeted preparation.
                  </p>
                </div>
                <div className="mt-6 flex items-start gap-3">
                  {yellowTick}
                  <p className="poppins font-medium fs_xxsm text-[#101A2E]">
                    Engage in full-fledged mock assessments following the
                    current examination <br /> pattern.
                  </p>
                </div>
                <div className="mt-6 flex items-start gap-3">
                  {yellowTick}
                  <p className="poppins font-medium fs_xxsm text-[#101A2E]">
                    Benefit from a curriculum designed to address specific
                    examination challenges <br /> and enhance overall
                    preparedness.
                  </p>
                </div>
              </div>
              <div className="text-center lg:text-start">
                <h3 className="poppins font-medium fs_xl mt-8">
                  Educator’s Qualification
                </h3>
                <p className="arial fs_xssm text-[#000000] max-w-[650px] mx-auto lg:mx-0">
                  Qualified with an MSc in Mathematics and BEd, I bring over 20
                  years of teaching expertise. Achieving exceptional results,
                  more than 70% of my learners have earned A or A* grades,
                  showcasing a commitment to academic excellence.
                </p>
              </div>
            </div>
          </div>
          <div className="w-[320px] sm:w-[370px] mx-auto lg:mx-0 pe-[24px">
            <p className="poppins font-medium fs_md mt-[16px]">
              Course Includes:
            </p>
            <div>
              <div className="flex justify-between mt-[14px]">
                <div className="flex items-center gap-3">
                  {dollar}
                  <p className="poppins fs_sm">Price:</p>
                </div>
                <p className="poppins fs_sm font-semibold text-[#FA786B]">
                  120 AED PER HOUR
                </p>
              </div>
            </div>
            <div className="w-[100%] h-[1px] bg-[#000000] opacity-[10%] mt-[18px]"></div>
            <div>
              <div className="flex justify-between mt-[14px]">
                <div className="flex items-center gap-3">
                  {teacherBlackColor}
                  <p className="poppins fs_sm">Instructor:</p>
                </div>
                <p className="poppins fs_sm font-medium text-[#081E1C]">
                  Asha Kaswan
                </p>
              </div>
            </div>
            <div className="w-[100%] h-[1px] bg-[#000000] opacity-[10%] mt-[18px]"></div>
            <div>
              <div className="flex justify-between mt-[14px]">
                <div className="flex items-center gap-3">
                  {dollar}
                  <p className="poppins fs_sm">Duration:</p>
                </div>
                <p className="poppins fs_sm font-medium text-[#081E1C]">
                  12 Weeks
                </p>
              </div>
            </div>
            <div className="w-[100%] h-[1px] bg-[#000000] opacity-[10%] mt-[18px]"></div>
            <div>
              <div className="flex justify-between mt-[14px]">
                <div className="flex items-center gap-3">
                  {dollar}
                  <p className="poppins fs_sm">Language:</p>
                </div>
                <p className="poppins fs_sm font-medium text-[#081E1C]">
                  English
                </p>
              </div>
            </div>
            <button
              className="bg-[#23A198] w-[100%] lg:mx-0 py-[17px] mt-8 px-[20px] flex items-center justify-center text-lg font-bold text-white arial rounded-full joinbtn_shadow"
              type="button"
              onClick={handleNavigation}
            >
              Start now {openRightArrow}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseDescription;
