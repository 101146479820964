import React from "react";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const DropDown = () => {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="flex items-center ff_poppins text-[#526260] text-base font-normal">
          EN
          <svg
            className="mt-[2px] ms-1"
            width="10"
            height="5"
            viewBox="0 0 10 5"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.97716 0.308106C8.85996 0.190937 8.70102 0.125115 8.53529 0.125115C8.36956 0.125115 8.21062 0.190937 8.09341 0.308106L4.99966 3.40186L1.90591 0.308106C1.78804 0.194258 1.63016 0.131261 1.46629 0.132685C1.30242 0.134109 1.14566 0.19984 1.02978 0.31572C0.913898 0.4316 0.848167 0.588358 0.846744 0.752231C0.84532 0.916104 0.908316 1.07398 1.02217 1.19186L4.55779 4.72748C4.67499 4.84465 4.83394 4.91047 4.99966 4.91047C5.16539 4.91047 5.32433 4.84465 5.44154 4.72748L8.97716 1.19186C9.09433 1.07465 9.16016 0.915709 9.16016 0.749981C9.16016 0.584254 9.09433 0.425311 8.97716 0.308106Z"
              fill="#4C4C4C"
            />
          </svg>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 origin-top-right">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <a
                  href="#"
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "block text-sm"
                  )}
                >
                  HINDI
                </a>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default DropDown;
