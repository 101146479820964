import React, { useState } from "react";
import {
  doubleComma,
  openRightArrow,
  rightFaceArrowslick,
  studentfeeedbacks,
} from "./Mapdata/MapData";
import Slider from "react-slick";
import greencircle from "../assets/img/png/widegreendotscircle.png";
import { useNavigate } from "react-router-dom";

const FeedBacks = () => {
  const [nav2, setNav2] = useState();
  const button = React.useRef();
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate("/ContactUs");
  };
  return (
    <div className="pt-[28px] pb-[88px] overflow-hidden sec-feedback">
      <div className="max-w-[1140px] mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 items-center">
          <div className="text-center lg:text-start px-[24px]">
            <h2 className="lora fs_xxl font-bold">
              What Our Students{" "}
              <span className="text-[#23A198] block">Have to Say</span>
            </h2>
            <p className="arial text-[#636363] fs_xxsm">
              Discover what our valued learners have to say about their
              transformative experiences with our math training program. Explore
              testimonials that showcase the impact of expert guidance and
              personalized learning on their mathematical journey.
            </p>

            <button
              className="bg-[#23A198] mx-auto lg:mx-0 arial mt-[30px] py-[22px] px-10 flex items-center text-lg font-bold text-white rounded-full joinbtn_shadow"
              type="button"
              onClick={handleNavigation}
            >
              GET STARTED
              {openRightArrow}
            </button>
            <button
              className="hidden lg:block next-btn bg-[#23A198] relative z-10 mt-[34px] w-[40px] h-[40px] rounded-[8px]"
              onClick={() => button.current.slickNext()}
            >
              {rightFaceArrowslick}
            </button>
          </div>
          <Slider
            className=""
            ref={(slider2) => {
              setNav2(slider2);
              button.current = slider2;
            }}
            slidesToShow={1}
            swipeToSlide={true}
            focusOnSelect={true}
            speed={500}
            dots={true}
            arrows={true}
            responsive={[
              {
                breakpoint: 992,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 576,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
            ]}
          >
            {studentfeeedbacks.map((items, index) => {
              return (
                <div key={index} className="lg:px-8 px-4 py-[48px]">
                  <div
                    style={{ boxShadow: "0px 9px 30px 0px #0B3B3724" }}
                    className="flex flex-col justify-between p-8 bg-white rounded-[34px] relative overflow-hidden h-[100%]"
                  >
                    <img
                      className="absolute top-[-22%] right-[-15%]"
                      src={greencircle}
                      alt="dots"
                    />
                    <div>
                      <div className="relative w-[91px]">
                        <img
                          className="w-[91px]"
                          src={items.studengImg}
                          alt="studens"
                        />
                        <div className="absolute top-[65%] right-[-10px] border-[4px] border-[white] bg-[#23A198] w-[38px] h-[38px] rounded-[100%] flex items-center justify-center">
                          {doubleComma}
                        </div>
                      </div>
                      <p className="arial fs_xxsm text-[#636363] mt-8">
                        {items.review}
                      </p>
                    </div>
                    <div>
                      <div className="flex items-center mt-4">
                        {items.reviewstar}
                      </div>
                      <p className="arial font-bold fs_sm mt-4">
                        {items.studentName}
                      </p>
                      <p className="arial fs_xxsm mt-3">Student</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default FeedBacks;
